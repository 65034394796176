<template>
  <div class="mt-3">
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Total Komisi</label>
        <vs-input class="w-full" :value="initData.header.total_komisi | idr" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Total Bayar</label>
        <vs-input class="w-full" :value="initData.header.total_bayar | idr" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Total Sisa</label>
        <vs-input class="w-full" :value="initData.header.total_sisa | idr" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Persen Komisi</label>
        <vs-input class="w-full" :value="initData.header.persen_komisi + '%'" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Harga Dasar Komisi</label>
        <vs-input class="w-full" :value="initData.header.harga_dasar_komisi | idr" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Closing Fee</label>
        <vs-input class="w-full" :value="initData.header.closing_fee | idr" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">PPh Jenis</label>
        <vs-input class="w-full" :value="initData.header.pph_nama" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">PPh</label>
        <vs-input class="w-full" :value="idr(initData.header.pph_jml) + ' (' + initData.header.pph_persen + '%)'" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Status NPWP</label>
        <vs-input class="w-full" :value="initData.header.status_npwp" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-12/12 w-full">
        <label class="ml-1 text-xs">Keterangan</label>
        <vs-textarea class="w-full" icon-pack="feather" icon="icon-map-pin" :value="initData.header.keterangan" readonly/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabDetailKomisi',
  props: {
    isActive: { type: Boolean },
    initData: { type: Object }
  },
  data () {
    return {}
  },
  methods: {
    idr (value, decimal = 2) {
      return this.$options.filters.idr(value, decimal)
    }
  }
}
</script>
